import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import About from './components/About/About';
import AdminDashboard from './components/Admin/AdminDashboard';
import LoginForm from './components/Auth/LoginForm';
import FlightSchedule from './components/Flights/FlightSchedule';
import PageViewTracker from './components/Analytics/PageViewTracker';
import { AuthProvider } from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { useAuth } from './contexts/AuthContext';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const AppContent = () => {
    const { isAuthenticated } = useAuth();
    console.log('AppContent rendering');

    return (
        <Layout>
            <PageViewTracker />
            <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/about" element={<About />} />
                <Route path="/home" element={<Home />} />
                <Route path="/schedule" element={<FlightSchedule />} />
                <Route path="/login" element={<LoginForm />} />
                <Route
                    path="/admin/*"
                    element={
                        <ProtectedRoute>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
        </Layout>
    );
};

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <SettingsProvider>
                    <AppContent />
                </SettingsProvider>
            </AuthProvider>
        </Router>
    );
};

export default App;