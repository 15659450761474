// PageViewTracker.tsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../../services/api';

const PageViewTracker: React.FC = () => {
    const location = useLocation();

    // Add a console log right at component mount
    console.log('PageViewTracker mounted');

    useEffect(() => {
        // Add immediate console log when effect runs
        console.log('PageViewTracker effect running for path:', location.pathname);

        // Skip API paths
        if (location.pathname.startsWith('/api')) {
            return;
        }

        // Log before making request
        console.log('About to make analytics request');

        // Define tracking function
        const trackPageView = async () => {
            try {
                // Log the attempt
                console.log('Making analytics request for path:', location.pathname);

                // Make the request
                await api.analytics.trackPage({
                    path: location.pathname
                });

                // Log success
                console.log('Analytics request successful');
            } catch (error) {
                // Log any errors
                console.error('Analytics request failed:', error);
            }
        };

        // Call tracking function
        trackPageView();

        // Add cleanup function to verify effect cleanup
        return () => {
            console.log('PageViewTracker effect cleanup for path:', location.pathname);
        };
    }, [location.pathname]);

    // Add a visible element to verify mounting
    return (
        <div style={{ display: 'none' }} data-testid="page-view-tracker">
            Page View Tracker Active
        </div>
    );
};

export default PageViewTracker;